import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "moment/locale/ru";
import "chartjs-adapter-moment";
//require('moment/moment');
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
let moment = require('moment');
let token = 'cced0854c15c918e0ad22f49ece0a984f16e4da295e76e5f1d2b1025721d6e50';
window.moment = moment;

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//window.axios.defaults.headers.common['Accept'] = 'application/json';


Vue.config.productionTip = false;

new Vue({
  data(){return{
    chartList:[]
  }},
  computed:{
    charts(){
      return this.$store.getters.getChartlist;
    }
  },
  created(){

    this.$store.dispatch('loadCharts');


  },
  router,
  store,
  render: h => h(App)
}).$mount('#app');
