<template>

    <div class="not-auth">

        <div class="icon-center">
            <b-icon
                    :icon="icon"
                    animation="throb"
                    :variant="variant"
                    font-scale="4"
            ></b-icon>
            <br>
            <br>
            <p :class="textClass">
                <slot></slot>
            </p>
        </div>

    </div>
</template>

<script>
    export default {
        name: "error",
        props:{
            variant:{
                type: String,
                default: 'danger'
            },
            icon:{
                type: String,
                default: 'shield-lock'
            }
        },
        computed:{
            textClass(){
                return 'text-' + this.variant;
            }
        }
    }
</script>

<style scoped>

    .not-auth{
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        padding: 0; margin: 0;
        background: rgba(51,51,51,.95);
    }
    .icon-center{
        margin-top: calc(20% - 80px);
    }
</style>