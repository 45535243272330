import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        users:[],
        user:{
            ID:0,
            NAME:"Not",
            LAST_NAME:"Auth",
            PERSONAL_GENDER:"M",
            TIME_ZONE:"Asia/Aqtau",
            TIME_ZONE_OFFSET: 18000,
            PERSONAL_PHOTO:"https://b24.adver.kz/upload/resize_cache/main/34d/100_100_2/3554557.png",
            ADMIN: false
        },
        url:{
            domen:'https://graph.adver.kz/api/',
            chart:{
                list: 'graph/list',
                create: 'graph/create'
            },
            dataset:{
                create:'dataset/create/'
            },
            user:{
                list:'user/list'
            },
            chartData:{
                create: 'graphdata/add/'
            }
        },
        charts:[],
        dashboards:[],
        datas: [/*
      {
        chartId:1,
        data: {
          labels: ['2020-03-21','2020-03-22','2020-03-23','2020-03-24','2020-03-25','2020-03-26','2020-03-27','2020-03-28','2020-03-29','2020-03-30','2020-03-31'],
          datasets: [
              {
                label: 'План',
                type: 'bar',
                data: [23, 23, 50, 32, 50, 100, 200, 250, 300, 350],
                backgroundColor: "rgba(84,84,84,0.1)",
                borderColor: "transparent",
                borderWidth: 2,
                fill: 'false' //[false, 'origin', 'start', 'end']
              },
              {
                type: 'line',
                label: 'Ванова',
                data: [28, 32, 10, 20, 80, 12,180, 230],
                backgroundColor: 'rgba(153, 102, 255, 1)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 2,
                fill: 'false' //[false, 'origin', 'start', 'end']
              },
          ]
        }
      }
      */
        ]
    },
    getters:{
        getUserList: state => {
            return state.users;
        },
        getUser: state => {
            let user = state.user;
            user.full_name = user.NAME + ' ' + user.LAST_NAME;
            return state.user;
        },
        getChartlist: state => {
            return state.charts;
        },
        chartById: state => chartID => {
            return state.charts.data.filter(chart => chart.id == chartID);
        },
        datasetByChartId: state => chartID => {
            return state.datas.filter(dataset => dataset.chartId == chartID);
        }
    },
    mutations: {
        saveCharts(state, data){
            state.charts = data;
        },
        saveProfile(state, data){
            state.user = data;
        },
        saveUsers(state, data){
            state.users = data;
        }
    },
    actions: {
        loadCharts(context){
            console.log('before load');
            window.axios.get(context.state.url.domen + context.state.url.chart.list).then( resp => {
                console.log('in load');
                context.commit('saveCharts', resp.data);

            }).catch(err => console.error(err));

            console.log('after load');

        },
        loadProfile(context){
            BX24.callMethod('profile', {}, function(res){
                console.log(res.data());
                context.commit('saveProfile', res.data());
            });
        },
        loadUsers(context){
            return new Promise((resolve, reject) => {
                window.axios.get(context.state.url.domen + context.state.url.user.list).then( resp => {

                    console.log('Users',resp.data.data);
                    context.commit('saveUsers',resp.data.data);

                    resolve(true)
                }).catch(err => {
                    console.error(err);
                    reject(err);
                });
            });
        },
        addChartData({dispatch, state}, data){

            return new Promise((resolve, reject) => {

                window.axios.post(state.url.domen + state.url.chartData.create + data.did, data).then( resp => {
                    //console.log('in load');
                    if(resp.data.status == 'ok'){
                        //dispatch('loadCharts');
                        resolve(true)
                    }else{
                        resolve(false)
                    }
                }).catch(err => {
                    console.error(err);
                    reject(err);
                });
            });
        },
        addDataSet({state}, data){

            return new Promise((resolve, reject) => {

                window.axios.post(state.url.domen + state.url.dataset.create + data.graph_id, data).then( resp => {
                    //console.log('in load');
                    if(resp.data.status == 'ok'){
                        //dispatch('loadCharts');
                        resolve(true)
                    }else{
                        resolve(false)
                    }
                }).catch(err => {
                    console.error(err);
                    reject(err);
                });
            });
        },
        createChart({dispatch, state}, data){
            return new Promise((resolve, reject) => {
                window.axios.post(state.url.domen + state.url.chart.create, data).then( resp => {
                    //console.log('in load');
                    console.log();
                    if(resp.data.status == 'ok'){
                        //dispatch('loadCharts');
                        resolve(true)
                    }else{
                        resolve(false)
                    }
                }).catch(err => {
                    console.error(err);
                    reject(err);
                });
            });
        },
    },
    modules: {
    }
})
