<template>
    <b-col :lg="chartConfig.cols">
        <b-card
                tag="article"
                class="mb-2 position-relative"
        >
            <div v-if="issetChart">
                <b-dropdown variant="light" class="rounded-circle bAdd" no-caret>
                    <template #button-content>
                        <b-icon-tools class="bIcon bicon_light"></b-icon-tools>
                    </template>
                    <b-dropdown-item v-if="is_created" @click.prevent="toggleModal">
                        <b-icon-plus-circle></b-icon-plus-circle> данные
                    </b-dropdown-item>
                    <b-dropdown-item v-if="is_admin" @click.prevent="toggleDatasetModal">
                        <b-icon-plus-square></b-icon-plus-square> набор
                    </b-dropdown-item>
<!--                    <b-dropdown-item>Third Action</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item disabled>Disabled action</b-dropdown-item>-->
                </b-dropdown>

<!--                <b-button  variant="light" class="rounded-circle bAdd">
                    <b-icon-plus-circle class="bIcon"></b-icon-plus-circle>
                </b-button>-->
                <b-button :id="'popover-help-chart' + chartConf.id" class="rounded-circle bInfo" variant="light">
                    <b-icon-info-circle class="bIcon"></b-icon-info-circle>
                </b-button>

                <b-popover :target="'popover-help-chart' + chartConf.id" triggers="hover focus" placement="Left">
                    <template #title>{{chartConf.info.title}}</template>
                    <div v-html="chartConf.info.body"></div>
                </b-popover>


                <!--        <b-popover target="popover-info" triggers="click" placement="left">
                            <template #title>{{ chartConf.title }}</template>
                            {{ chartConf.body }}
                        </b-popover>-->
                <base-chart :chart-data="dataset" :title="chartConfig.title" :xLabel="chartConfig.xLabel" :yLabel="chartConfig.yLabel" :ticks-max="maxValue" :ticks-step="stepValue" :ticks-reverse="chartConfig.ticksReverse"></base-chart>
            </div>

            <div v-else>
                <br>
                <br>
                <b-skeleton-img></b-skeleton-img>
            </div>
            <!--<div v-else>
                <b-alert show variant="warning">
                    <h4 class="alert-heading">Данных нет</h4>
                    <p>
                        К сожалению такого графика не существует или он был удален
                    </p>
                    <hr>
                    <p class="mb-0">
                        Попробуйте изменить настройки, или обновить страницу <br>
                        если проблема не уходит, обратитесь в отдел <b>РиА</b>
                    </p>
                </b-alert>
            </div>-->



            <b-modal ref="addDataPopUp" hide-footer :title="'Добавить в ' + chartConfig.title">
                <div class="d-block">


                    <b-input-group class="mb-2">
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-calendar2-plus></b-icon-calendar2-plus>
                            </b-input-group-text>
                        </template>
                        <b-form-input v-model="create.date" id="creationDate" type="date"></b-form-input>
                    </b-input-group>


                    <b-input-group class="mb-2">
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-plus-circle></b-icon-plus-circle>
                            </b-input-group-text>
                        </template>
                        <b-form-input v-model="create.value" id="creationNumber" type="number" min="0"></b-form-input>
                    </b-input-group>

                </div>
                <div class="text-center" v-if="newDateIsValid">
                    <b-button class="mt-2" variant="outline-primary" @click="addData">Добавить</b-button>
                </div>
            </b-modal>


            <b-modal ref="addDataSetPopUp" size="lg" hide-footer title="Добавить набор ">
                <div class="d-block">



                    <b-input-group class="mb-2">
                        <template #prepend>
                            <b-input-group-text>
                                <b-icon-people></b-icon-people>
                            </b-input-group-text>
                        </template>
                        <b-form-select v-model="datasetFrom.user_id" :options="selectUserList"></b-form-select>
                    </b-input-group>

                    <b-input-group class="mb-2">
                        <template #prepend>
                            <b-input-group-text>
                                Толщина линии
                            </b-input-group-text>
                        </template>
                        <b-form-input v-model="datasetFrom.borderWidth" type="number" min="1" max="4"></b-form-input>
                    </b-input-group>

                    <b-input-group class="mb-2">
                        <template #prepend>
                            <b-input-group-text>
                                Цвет линии
                            </b-input-group-text>
                        </template>
                        <b-form-input v-model="datasetFrom.backgroundColor" type="color"></b-form-input>
                    </b-input-group>

                    <b-input-group class="mb-2">
                        <template #prepend>
                            <b-input-group-text>
                                Цвет обводки линии
                            </b-input-group-text>
                        </template>
                        <b-form-input v-model="datasetFrom.borderColor" type="color"></b-form-input>
                    </b-input-group>

                </div>
                <div class="text-center" v-if="datasetFormValid">
                    <b-button class="mt-2" variant="outline-primary" @click="addDataSet">Добавить</b-button>
                </div>
            </b-modal>

        </b-card>
    </b-col>
</template>

<script>
    import BaseChart from "./BaseChart";
    export default {
        name: "Chart",
        props:{
            id:{
                type:Number,
                required: true
            }
        },
        data(){return{
            datasetFrom:{
                user_id: null,
                graph_id: null,
                borderWidth: 2,
                backgroundColor: '#9966ff',
                borderColor: '#9966ff',
            },
            dataset:{},
            chartConfig:{},
            create:{
                date:null,
                value:0,
            }
        }},
        created(){
            this.updateChartConfig();
            this.updateChartDatasets();
        },
        computed:{
            is_created(){
                let a = 0;
                this.getChartDataSet().datasets.forEach(el => {
                    if(this.user.ID == el.user)
                        a++;
                });
                if(a > 0){
                    return true;
                }
                return  false;
            },
            is_admin(){
                return this.user.ADMIN;
            },
            dataSetID(){
                let a = null;
                this.getChartDataSet().datasets.forEach(el => {
                    if(this.user.ID == el.user)
                        a = el.d_id;
                });
                return  a;
            },
            maxValue(){
                let max = 0;
                this.getChartDataSet().datasets.forEach(el => {
                    let val= Math.max.apply(null, el.data);
                    if(max < val){
                        max = val;
                    }
                });

                return Math.round(max += (max*0.2))
            },
            stepValue(){
                return Math.round(this.getMaxValue/10)
            },
            newDateIsValid(){
                return window.moment(this.create.date).isValid() && Number.isInteger(parseInt(this.create.value))
            },
            issetChart(){
                let data = this.chartConf;
                return data != null || typeof data != 'undefined';
            },
            chartConf(){
                return this.getChartConfig()[0];
            },
            ChartDataSet(){
                if(this.issetChart)
                    return this.getChartConfig()[0].data;
                return [];
            },
            user(){
                return this.$store.getters.getUser;
            },
            datasetFormValid(){
                let errs = 0;
                if(this.datasetFrom.user_id == null)
                    errs++;
                if(this.datasetFrom.borderColor == null)
                    errs++;
                if(this.datasetFrom.backgroundColor == null)
                    errs++;
                if(this.datasetFrom.borderWidth == null)
                    errs++;

                return errs == 0;
            },
            issetUserList(){
                return this.userList.length > 0;
            },
            selectUserList(){
                let users = [];
                this.userList.forEach((el) => {
                    users.push({
                        text: el.name,
                        value: el.id,
                    })
                });

                return users;
            },
            userList(){
                return this.$store.getters.getUserList;
            }
        },
        methods:{
            updatePage(){
                this.$router.go(this.$router.currentRoute);
            },
            updateChartConfig(){
                this.chartConfig = this.getChartConfig()[0];
            },
            updateChartDatasets(){
                this.dataset = this.ChartDataSet;
            },
            getChartConfig(){
                return this.$store.getters.chartById(this.id)
            },
            getChartDataSet(){
                return this.getChartConfig()[0].data;
            },
            toggleModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.$refs['addDataPopUp'].toggle('#toggle-btn')
            },
            toggleDatasetModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.$refs['addDataSetPopUp'].toggle('#toggle-btn')
            },
            makeToast(message, title = 'Уведомление', variant = null){
                this.$bvToast.toast(message, {
                    title: title,
                    variant: variant,
                    solid: true
                });
            },
            addData(){
                let createData = this.create;
                createData.did = this.dataSetID;
                createData.user_id = this.user.ID;
                this.$store.dispatch('addChartData', createData).then(result => {
                    if(result){
                        this.makeToast('Данные успешно добавлены', 'Изменение набора данных', 'success');
                        this.updateChartConfig();
                        this.updateChartDatasets();
                        this.$forceUpdate();
                    }else{
                        this.makeToast('Не удалось добавить данные', 'Изменение набора данных', 'danger');
                    }
                }).finally(() => {
                    this.$refs['addDataPopUp'].hide();
                    this.updatePage();
                });
            },
            addDataSet(){
                this.datasetFrom.graph_id = this.chartConf.id;
                console.log(this.datasetFrom);
                this.$store.dispatch('addDataSet', this.datasetFrom).then(result => {
                    if(result){
                        this.makeToast('Набор данных успешно добавлен', 'Создание набора данных', 'default');
                    }else{
                        this.makeToast('Не удалось добавить набор', 'Создание набора данных', 'danger');
                    }
                }).finally(() => {
                    this.$refs['addDataSetPopUp'].hide();
                    this.updatePage();
                });
            }
        },
        watch:{
        },
        components:{
            BaseChart
        }
    }
</script>

<style scoped>
    .bInfo{
        height: 32px;
        width: 32px;
        font-size: 1em;
        position: absolute;
        top: 1rem; right: 1rem;
    }
    .bAdd{
        height: 32px;
        width: 32px;
        font-size: 1em;
        position: absolute;
        top: 1rem; left: 1rem;
    }
    .bicon_light{
        color: #cecece;
    }
    .bIcon{
        position: absolute;
        top: 5px; left:5px;
    }
</style>