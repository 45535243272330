<template>
  <div class="home">
    <b-row>
      <chart v-for="chart in chartList.data" :id="chart.id" :key="chart.id"></chart>
    </b-row>
    <div style="height: 400px">
dsds
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Chart from "../components/charts/Chart";
export default {
  name: 'Home',
  data(){return{
  }},

  computed:{
    chartList(){
      return this.$store.getters.getChartlist;
    }
  },

  methods:{
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
    }
  },
  created(){

  },
  components:{
    Chart
  }
}
</script>
