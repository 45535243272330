<template>
    <div id="app" class="container-fluid">
        <div id="nav">
            <navbar/>
        </div>
        <error v-if="user.ID == 0" icon="shield-lock" variant="info">
            Не удается загрузить <br>информацию о вас
            <br>
            <br>
            <b-button variant="outline-info" @click="updatePage">Обновить</b-button>
        </error>
        <b-container v-else fluid class="mt-3">
            <router-view/>
        </b-container>
    </div>
</template>

<script>
    import Navbar from "./components/Navbar";
    import Error from "./components/error";
    export default {
        components: {
            Error,
            Navbar
        },
        computed:{
            user(){
                return this.$store.getters.getUser;
            }
        },
        methods:{
            updatePage(){
                location.reload();
            }
        },
        created(){
            this.$store.dispatch('loadProfile');
            this.$store.dispatch('loadUsers');
        },
    }
</script>

<style lang="scss">
    @import '~bootstrap/scss/bootstrap.scss';
    @import '~bootstrap-vue/src/index.scss';

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin: 0;
        padding: 0;
    }
</style>
