<template>
    <ch-line :chart-data="cData" :options="options"></ch-line>
</template>

<script>
    import ChLine from "./Line";
    export default {
        name: "BaseChart",
        components:{
            ChLine
        },

        props:{
            chartData:{
                type: Object,
                required: true
            },
            title:{
                type:String,
                default: "Базовый график"
            },
            xLabel:{
                type:String,
                default: "Базовые данные"
            },
            yLabel:{
                type:String,
                default: "Базовые значения"
            },
            ticksStep:{
                type: Number,
                default: 10
            },
            ticksMin:{
                type: Number,
                default: 0
            },
            ticksMax:{
                type: Number,
                default: 100
            },
            responsive:{
                type: Boolean,
                default: true
            },
            ticksReverse:{
                type: Boolean,
                default: false
            },
            ticksMirror:{
                default: false
            },
            lineTension:{
                type: Number,
                default: 0.4
            },
        },

        data(){return{
            cData: this.chartData,

            options: {
                responsive: this.responsive,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: this.title
                },
                tooltips: {
                    mode: 'index',
                    axis: 'y',
                    intersect: true,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                elements: {
                    line: {
                        tension: 0.4//0.000001
                    }
                },
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: this.xLabel
                        },
                        type: 'time',
                        //distribution: 'series',
                        time: {
                            unit: 'day',
                            tooltipFormat:'D MMM YYYY',
                            displayFormats: {
                                day: 'D MMM YYYY',
                                month: 'MMM YYYY',
                                year: 'YYYY',
                            }
                        },
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: this.yLabel
                        },
                        ticks: {
                            beginAtZero: true,
                            min: this.ticksMin,
                            max: this.ticksMax,
                            stepSize: this.reduceStep(),
                            mirror: this.ticksMirror,
                            reverse: this.ticksReverse,

                            callback: (value) => {//, index, values) {
                                return this.reduc(value);
                            }
                        }
                    }]
                }
            }
        }},

        methods:{
            reduc(value){
                if (value<1e3)  return value;
                if (value<1e6)  return (value - value%1e3)/1e3 + " тыс.";
                if (value<1e9)  return (value - value%1e6)/1e6 + " Млн.";
                if (value<1e12) return (value - value%1e9)/1e9 + " Млрд.";
                if (value<1e15) return (value - value%1e12)/1e12 + " Трил.";
                return value;
            },
            reduceStep(){
                if ( this.ticksStep != null){
                    return this.ticksStep
                }

                if (this.ticksMax<1e3)  return this.ticksStep;
                if (this.ticksMax<1e6)  return (1000 < this.ticksStep)? this.ticksStep : 1000;
                if (this.ticksMax<1e9)  return (1000000 < this.ticksStep)? this.ticksStep : 1000000;
                if (this.ticksMax<1e12) return (1000000000 < this.ticksStep)? this.ticksStep : 1000000000;
                if (this.ticksMax<1e15) return (1000000000000 < this.ticksStep)? this.ticksStep : 1000000000000;
                return this.ticksStep;
            }
        }
    }
</script>

<style scoped>

</style>