<template>
    <b-navbar toggleable="sm" type="light" variant="light">
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

        <b-navbar-brand>
            <b-avatar :src="user.PERSONAL_PHOTO" :badge="user.ADMIN" badge-offset="-0.3em" :title="user.full_name"></b-avatar>
        </b-navbar-brand>

        <b-collapse id="nav-text-collapse" is-nav>
            <b-navbar-nav>
                <!--                        <b-nav-text>Navbar text</b-nav-text>-->
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item to="/">Главная</b-nav-item>
                <b-nav-item to="/about">О графике</b-nav-item>
                <b-nav-item-dropdown right>
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        {{ user.NAME }}
                    </template>
                    <b-dropdown-item to="/profile">Profile</b-dropdown-item>
                    <b-dropdown-item to="/graph/create">Добавит график</b-dropdown-item>
                    <!--                            <b-dropdown-item href="#">Sign Out</b-dropdown-item>-->
                </b-nav-item-dropdown>
            </b-navbar-nav>

        </b-collapse>
    </b-navbar>
</template>

<script>
    export default {
        name: "Navbar",
        computed:{
            user(){
                return this.$store.getters.getUser;
            }
        },
    }
</script>

<style scoped>

</style>