<script>
    import { Line, mixins } from 'vue-chartjs'
    const { reactiveProp } = mixins;
    export default {
        name: "ChLine",
        mixins: [reactiveProp],
        props:{
            options:{
                type:Object
            }
        },
        data(){return{
            
        }},
        extends: Line,
        mounted () {
            this.renderChart(this.chartData, this.options)
        }

    }
</script>

<style scoped>

</style>